.missing {
    background-color: skyblue;
}
/* export const ORDER_STATUS = ['Đặt Hàng', 'Đang Xử Lý', 'Đã Lập Lịch', 'Đang Vận Chuyển', 'Đến Nơi', 'Huỷ', 'Sự Cố', 'Hoàn Thành'] */

.invoiceStatus0 {
    background-color: #ffc892;
  }
  .invoiceStatus1 {
    background-color: #fff3c4;
  }
  .invoiceStatus2 {
    background-color: #a38932;
  }
  .invoiceStatus3 {
    background-color: #d1f1b8;
  }
  .invoiceStatus4 {
    background-color: #c4e3e6;
  }
  .invoiceStatus5 {
    background-color: #C6C6C6;
    color:#828282
  }
  .invoiceStatus6 {
    background-color: #f1a3a3;
  }
  .invoiceStatus7 {
    background-color: #8ACCFF;
  }

  .orderCode {
      cursor: pointer;
      color: rgb(0, 38, 109);
  }