.item-price-table{
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}
.item-price-table:hover{
  background-color: rgb(88, 179, 240);
}
.missing {
    background-color: skyblue;
}
/* export const ORDER_STATUS = ['Đặt Hàng', 'Đang Xử Lý', 'Đã Lập Lịch', 'Đang Vận Chuyển', 'Đến Nơi', 'Huỷ', 'Sự Cố', 'Hoàn Thành'] */

.invoiceStatus0 {
    background-color: #ffc892;
  }
  .invoiceStatus1 {
    background-color: #fff3c4;
  }
  .invoiceStatus2 {
    background-color: #a38932;
  }
  .invoiceStatus3 {
    background-color: #d1f1b8;
  }
  .invoiceStatus4 {
    background-color: #c4e3e6;
  }
  .invoiceStatus5 {
    background-color: #C6C6C6;
    color:#828282
  }
  .invoiceStatus6 {
    background-color: #f1a3a3;
  }
  .invoiceStatus7 {
    background-color: #8ACCFF;
  }

  .orderCode {
      cursor: pointer;
      color: rgb(0, 38, 109);
  }
.rdw-editor-main {
  min-height: 300px;
}
.rdw-editor-toolbar {
  border: none !important;
  border-bottom: 1px solid #f1f1f1 !important;
}
.public-DraftStyleDefault-block {
  margin: 0 0 1em;
}
.rdw-editor-wrapper {
  border: 1px solid #f1f1f1;
}
.DraftEditor-root {
  padding: 10px 15px;
}

.custom-card-hoverable:hover {
    background-color: #e6f7ff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*
* react-drop-n-crop
*/
.drop-n-crop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
/*
* react-dropzone
*/
.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  background: #fff;
  border: 2px dashed #ddd;
  padding: 8px;
  transition: all 300ms ease-in-out;
}
.dropzone.dropzone--active {
  background: #f2f2f2;
  border-color: #b1b1b1;
}
/*
* react-drop-n-crop default dropzone instructions & validation
*/
.dropzone-instructions {
  transition: all 300ms ease-in-out;
}
.dropzone.dropzone--active .dropzone-instructions {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.dropzone-instructions--main {
  color: #322d35;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
}
.dropzone-instructions--sub {
  color: #201c23;
  font-style: italic;
  margin-top: 4px;
}
.dropzone-validation {
  color: #ff3d00;
  font-size: 16px;
  font-weight: bold;
  margin-top: 32px;
}
/*
* Cropper.js v1.0.0-beta.2
* https://github.com/fengyuanchen/cropperjs
*/
.cropper-container {
  font-size: 0;
  line-height: 0;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  direction: ltr;
  touch-action: none;
}
.cropper-container img {
  /* Avoid margin top issue (Occur only when margin-top <= -height) */
  display: block;
  min-width: 0 !important;
  max-width: none !important;
  min-height: 0 !important;
  max-height: none !important;
  width: 100%;
  height: 100%;
  image-orientation: 0deg;
}
.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cropper-wrap-box {
  overflow: hidden;
}
.cropper-drag-box {
  opacity: 0;
  background-color: #fff;
}
.cropper-modal {
  opacity: 0.5;
  background-color: #000;
}
.cropper-view-box {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  outline: 1px solid #39f;
  outline-color: rgba(51, 153, 255, 0.75);
}
.cropper-dashed {
  position: absolute;
  display: block;
  opacity: 0.5;
  border: 0 dashed #eee;
}
.cropper-dashed.dashed-h {
  top: 33.33333%;
  left: 0;
  width: 100%;
  height: 33.33333%;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.cropper-dashed.dashed-v {
  top: 0;
  left: 33.33333%;
  width: 33.33333%;
  height: 100%;
  border-right-width: 1px;
  border-left-width: 1px;
}
.cropper-center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  opacity: 0.75;
}
.cropper-center:before,
.cropper-center:after {
  position: absolute;
  display: block;
  content: " ";
  background-color: #eee;
}
.cropper-center:before {
  top: 0;
  left: -3px;
  width: 7px;
  height: 1px;
}
.cropper-center:after {
  top: -3px;
  left: 0;
  width: 1px;
  height: 7px;
}
.cropper-face,
.cropper-line,
.cropper-point {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}
.cropper-face {
  top: 0;
  left: 0;
  background-color: #fff;
}
.cropper-line {
  background-color: #39f;
}
.cropper-line.line-e {
  top: 0;
  right: -3px;
  width: 5px;
  cursor: e-resize;
}
.cropper-line.line-n {
  top: -3px;
  left: 0;
  height: 5px;
  cursor: n-resize;
}
.cropper-line.line-w {
  top: 0;
  left: -3px;
  width: 5px;
  cursor: w-resize;
}
.cropper-line.line-s {
  bottom: -3px;
  left: 0;
  height: 5px;
  cursor: s-resize;
}
.cropper-point {
  width: 5px;
  height: 5px;
  opacity: 0.75;
  background-color: #39f;
}
.cropper-point.point-e {
  top: 50%;
  right: -3px;
  margin-top: -3px;
  cursor: e-resize;
}
.cropper-point.point-n {
  top: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: n-resize;
}
.cropper-point.point-w {
  top: 50%;
  left: -3px;
  margin-top: -3px;
  cursor: w-resize;
}
.cropper-point.point-s {
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  cursor: s-resize;
}
.cropper-point.point-ne {
  top: -3px;
  right: -3px;
  cursor: ne-resize;
}
.cropper-point.point-nw {
  top: -3px;
  left: -3px;
  cursor: nw-resize;
}
.cropper-point.point-sw {
  bottom: -3px;
  left: -3px;
  cursor: sw-resize;
}
.cropper-point.point-se {
  right: -3px;
  bottom: -3px;
  width: 20px;
  height: 20px;
  cursor: se-resize;
  opacity: 1;
}
@media (min-width: 768px) {
  .cropper-point.point-se {
    width: 15px;
    height: 15px;
  }
}
@media (min-width: 992px) {
  .cropper-point.point-se {
    width: 10px;
    height: 10px;
  }
}
@media (min-width: 1200px) {
  .cropper-point.point-se {
    width: 5px;
    height: 5px;
    opacity: 0.75;
  }
}
.cropper-point.point-se:before {
  position: absolute;
  right: -50%;
  bottom: -50%;
  display: block;
  width: 200%;
  height: 200%;
  content: " ";
  opacity: 0;
  background-color: #39f;
}
.cropper-invisible {
  opacity: 0;
}
.cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}
.cropper-hide {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
}
.cropper-hidden {
  display: none !important;
}
.cropper-move {
  cursor: move;
}
.cropper-crop {
  cursor: crosshair;
}
.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}

.node-highlight {
  background-color: #94b6ce !important;
}
.ant-table-tbody > tr.ant-table-row-selected.node-highlight td {
  background: none;
}
.table-schedule-detail p {
  line-height: 25px;
  margin: 0px;
}
.table-schedule-detail .ant-card.completed {
  background-color: #3fea3f;
}
.table-schedule-detail .ant-card.shipped {
  background-color: #56b1ff;
}

.tableCode .ant-table-row.ant-table-row-level-0 .btnCode:hover {
  background-color: #e6f7ff;
}
.tableCode .ant-table-row.ant-table-row-level-0:hover .btnCode {
  background-color: #e6f7ff;
}
.btnCode {
  border: none;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0);
  color: rgb(24, 144, 255);
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.whitesmoke {
    background-color: whitesmoke;
}

.row-warning {
    border: 2px solid #FF0000;
}
/* table row alternative color */
.ant-table table  .even {
    background-color: whitesmoke;
  }
  
  /*** Responsive ***/
  /******************/
  
  /* .ant-table- {
      width: max-content;
  } */
  .ant-table table {
    width: auto !important;
    min-width: 100%;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    min-width: 80px;
  }
  .ant-card-extra {
    max-width: 100%;
  }
  .visible-md { display: none; }
  @media (min-width: 992px) {
    .visible-md { display: block; }
  }
  
  @media (max-width: 600px) {
    .hidden-sm      { display: none !important; }
  }
  @media (max-width: 992px) {
    .hidden-md      { display: none !important; }
    /* .ant-menu-inline-collapsed {
      width: 40px;
    }
    .ant-menu-inline-collapsed > .ant-menu-item,
    .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
    .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 0 12px !important;
    } */
    .ant-card-body {
      padding: 12px 4px;
      zoom: 1;
    }
  }
  @media (max-width: 1200px) {
    .hidden-lg      { display: block !important; }
  }
  @media (max-width: 1400px) {
    .hidden-xl      { display: block !important; }
  }

  @media (max-width: 992px) {
    .ant-menu-inline-collapsed {
      width: 40px;
    }
    .ant-menu-inline-collapsed > .ant-menu-item,
    .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
    .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 0 12px !important;
    }
    .ant-card-body {
      padding: 12px 4px;
      zoom: 1;
    }
  }
